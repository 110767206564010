import Vue from 'vue'
import "@/auth";

import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

import vuetify from './plugins/vuetify'
import App from './App.vue'

import pinia from "@/stores/store.js";
import router from '@/router/routes.js';

// Eventbus
export const eventBus = new Vue();

// Helper functions
import helpers from './helpers/helpers'
import {useMyFilesStore} from "@/stores/myfiles";

Vue.$keycloak
  .init({
    onLoad: "login-required",
  })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.log("Authentication successful");
      let payload = {
        accessToken: Vue.$keycloak.token,
        refreshToken: Vue.$keycloak.refreshToken,
      };

      // console.log("payload: ", payload);

      // Store the access and refresh token
      localStorage.setItem('accessToken', payload.accessToken);
      localStorage.setItem('refreshToken', payload.refreshToken)

      Vue.use(helpers);

      // Create the vue app
      new Vue({
        vuetify,
        pinia,
        router,
        render: h => h(App)
      }).$mount('#app')

      const auth = useAuthStore();
      auth.accessToken = Vue.$keycloak.token;
      Vue.$keycloak.loadUserProfile().then(function(data) {
        useUserStore().setUserID(data.id)
        useUserStore().fetchUser()
        useMyFilesStore().fetchMyFiles()
      })

      // Token refresh
      setInterval(() => {
        Vue.$keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            // console.log('Token refreshed');

            let payload = {
              accessToken: Vue.$keycloak.token,
              refreshToken: Vue.$keycloak.refreshToken,
            };

            // Uodate the access and refresh token
            localStorage.setItem('accessToken', payload.accessToken);
            localStorage.setItem('refreshToken', payload.refreshToken);
            auth.accessToken = Vue.$keycloak.token;
          } else {
            //console.log('Token not refreshed, valid for '  + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
          }
        }).catch(() => {
          console.log('Error: Failed to refresh token');
        });
      }, 6000);
    }
  })
  .error(() => {
    console.log("Authenticated failed");
  });

import {defineStore} from "pinia";

const SAMPLE_FILE_URL = "https://staticfiles.geoville.com/data/earth-receiver/sample.txt";

export const useDownloadRate = defineStore("downloadRate", {
  state: () => {
    return {
      downloadRate: null, // in (bytes / sec)
      downloadRateReady: false
    };
  },
  getters: {
    getDownloadRate(state) {
      return state.downloadRate;
    },
    getDownloadRateReady(state) {
      return state.downloadRateReady;
    },
  },
  actions: {
    async calculateDownloadRate() {
      let url = SAMPLE_FILE_URL;

      this.downloadRate = false;
      this.downloadRateReady = false;

      const response = await fetch(url, {cache: "no-store"});

      let downloadedBytes = 0;
      const start = performance.now();
      const reader = response.body.getReader();

      for(;;) {
        const {done, value} = await reader.read();
        if (done) break;
        downloadedBytes += value.length;
      }

      const end = performance.now();
      const downloadTime = (end - start) / 1000; // Convert to seconds

      this.downloadRate = downloadedBytes / downloadTime;
      this.downloadRateReady = true;
    },
  },
});


<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                    <v-text-field label="Username*" :rules="requiredRule" v-model="usernameFilled"
                        :disabled="action == 'update'"></v-text-field>
                    <v-text-field label="First name*" :rules="requiredRule" v-model="firstNameFilled"></v-text-field>
                    <v-text-field label="Last name*" :rules="requiredRule" v-model="lastNameFilled"></v-text-field>
                    <v-text-field label="Email*" type="email" :rules="emailRules" v-model="emailFilled"></v-text-field>
                    <v-text-field :disabled="this.action == 'update'" label="Contingent* [in GB]" :rules="contingentRule" type="number" v-model="contingentFilled"></v-text-field>
                    <v-radio-group v-model="roleFilled">
                        <v-radio label="User" value="earth_receiver_user"></v-radio>
                        <v-radio label="Admin" value="earth_receiver_administrator"></v-radio>
                    </v-radio-group>
                </v-container>
            </v-form>
            <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
                Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios-services";

export default {
    name: "NewUser",
    props: {
        username: String,
        firstName: String,
        lastName: String,
        email: String,
        role: String,
        id: String,
        action: String,
        contingent: Number,
    },
    data() {
        return {
            usernameFilled: "",
            firstNameFilled: "",
            lastNameFilled: "",
            emailFilled: "",
            roleFilled: "user",
            contingentFilled: 0,
            valid: true,
            contingentRule: [v => !!v || 'Required', v => (v > 0 && v < 100) || "Contingent must be between 0 and 100"],
            requiredRule: [v => !!v || 'Required'],
            emailRules: [v => !!v || 'Required',
            v => /.+@.+.\.+/.test(v) || 'E-mail must be valid',],
        }
    },
    mounted() {
        if (this.action === "update") {
            this.usernameFilled = this.username;
            this.firstNameFilled = this.firstName;
            this.lastNameFilled = this.lastName;
            this.emailFilled = this.email;
            this.roleFilled = this.roleMapper(this.role);
            this.contingentFilled = this.contingent;
        }
    },
    methods: {
        close() {
            this.$emit('close', this.id);
        },
        save() {
            if(!this.$refs.form.validate())
              return;
            let data = {
                username: this.usernameFilled,
                email: this.emailFilled,
                last_name: this.lastNameFilled,
                first_name: this.firstNameFilled,
            }
            if (this.action === 'new') {
                axios_services.post("oidc/users", data).then((response) => {
                    axios_services.put("oidc/users/realm-role", {"realm_role_name": this.roleFilled, "user_id": response.data.user_id})
                    axios_services.post("contingents", {user_id: response.data.user_id, contingent: this.contingentFilled}).then(() => { this.$emit('save');});
                    eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Created user." });
                });
            } else if (this.action === 'update') {
              axios_services.put("oidc/users/" + this.id, data).then(() => {
                console.log(this.roleFilled)
                    if (this.roleFilled !== this.roleMapper(this.role)) {
                        axios_services.put("oidc/users/realm-role", {"realm_role_name": this.roleFilled, "user_id": this.id})
                        axios_services.delete("oidc/users/" + this.id + "/realm-role/" + this.roleMapper(this.role)).then(() => {
                          eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Updated user." });
                          this.$emit('save', this.id);
                        });
                    }
                    else {
                      eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Updated user." });
                      this.$emit('save', this.id);
                    }
                });
            }
        },
      roleMapper(role) {
        if(role === 'admin') {
          return 'earth_receiver_administrator'
        }
        else if(role === 'user') {
          return 'earth_receiver_user'
        }
      }
    },
}
</script>
  
<style scoped>

</style>
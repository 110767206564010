import { render, staticRenderFns } from "./CommentDialog.vue?vue&type=template&id=2c6df77b&scoped=true&"
import script from "./CommentDialog.vue?vue&type=script&lang=js&"
export * from "./CommentDialog.vue?vue&type=script&lang=js&"
import style1 from "./CommentDialog.vue?vue&type=style&index=1&id=2c6df77b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c6df77b",
  null
  
)

export default component.exports
<template>
  <v-row>
    <v-col cols="7">
      <v-card elevation="0" class="rounded-lg">
        <v-data-table :headers="headersGroups" :items="groups" hide-default-footer class="elevation-1"
          :value="[groups[selectedRow]]" item-key="id">
          <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
              <v-toolbar-title>Groups
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="600px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    New Group
                  </v-btn>

                </template>
                <NewGroup @close="close" @save="save" />
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr :class="groups[selectedRow] === item ? 'selected' : ''" @click="rowClicked(item)">
              <td>{{ item.name }}</td>
              <td width="10%">
                <v-dialog v-model="dialogRemove[item.id]" max-width="600px" :retain-focus="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-icon small class="mr-2" v-bind="attrs" v-on="{ ...menu, ...tooltip }">
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete group</span>
                    </v-tooltip>
                  </template>
                  <v-card>
                    <v-card-title class="text-h6 grey lighten-2">
                      Remove Group: {{ item.name }}
                    </v-card-title>
                    <v-card-text>Are you sure you want to permanently delete the group?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialogRemove[item.id] = false">
                        Close
                      </v-btn>
                      <v-btn color="error" text @click="deleteGroup(item); dialogRemove[item.id] = false;">
                        Remove
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card elevation="0" class="rounded-lg">
        <v-data-table :headers="headersUsers" :items="users" hide-default-footer class="elevation-1"
          no-data-text="There are currently no users in this group." :loading="loadingUsers"
          loading-text="Loading... Please wait">
          <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
              <v-row>
                <v-col cols="3" class="horizontalCenterCol">
                  <v-toolbar-title>Members
                  </v-toolbar-title>
                </v-col>
                <v-col cols="1"></v-col>
                <v-col cols="7" class="horizontalCenterCol">
                  <v-autocomplete v-model="addUsers" :disabled="isUpdating" :items="allAvailableUsers"
                    hide-details="auto" clearable hide-no-data color="blue-grey lighten-2" label="Users"
                    item-text="username" item-value="username" multiple>
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                        @click:close="remove(data.item)">
                        {{ data.item.username }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.username"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="1" class="horizontalCenterCol">
                  <v-btn icon color="blue" :disabled="!addUsers[0]" @click="addMembersToGroup">
                    <v-icon>mdi-account-multiple-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small class="mr-2" @click="removeMemberFromGroup(item)" v-on="on">
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove member</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import axios_services from "@/axios-services";
import NewGroup from "./NewGroup.vue";
import { eventBus } from "@/main.js";
import { useGroupsStore } from '@/stores/groups';
import {useUsersStore} from "@/stores/users";


export default {
  name: "GroupsTable",
  data() {
    return {
      headersGroups: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersUsers: [
        {
          text: 'Name',
          align: 'start',
          value: 'username',
        },
        { text: 'Actions', value: 'actions', sortable: false, width: "10%" }
      ],
      addUsers: [],
      isUpdating: false,
      dialog: false,
      dialogRemove: {},
      selectedRow: null,
      loadingUsers: false,
    };
  },
  mounted() {
    if(this.groups.length > 0)
      this.rowClicked(this.groups[0]);
  },
  components: {
    NewGroup
  },
  computed: {
    groups() {
      return useGroupsStore().groups;
    },
    allAvailableUsers() {
      if(this.groups[this.selectedRow])
        return useUsersStore().getUsers.filter(({ username }) => !this.users.map(item => { return item.username }).includes(username));
      else
        return []
    },
    users() {
      if(this.groups[this.selectedRow])
        return useGroupsStore().groups.find(element => element.id === this.groups[this.selectedRow].id).members;
      else
        return [];
    }
  },
  methods: {
    rowClicked(row) {
      if (this.selectedRow) {
        if (row.id !== this.groups[this.selectedRow].id) {
          this.selectedRow = this.groups.indexOf(row);
        }
      } else {
        this.selectedRow = this.groups.indexOf(row);
      }
    },
    remove(item) {
      const index = this.addUsers.indexOf(item.username)
      if (index >= 0) this.addUsers.splice(index, 1)
    },
    close() {
      this.dialog = false;
    },
    save() {
      useGroupsStore().fetchGroups();
      this.close();
    },
    deleteGroup(group) {
      axios_services.delete("oidc/groups/" + group.id).then(() => {
        useGroupsStore().fetchGroups();
        eventBus.$emit("show-snackbar", { type: "success", title: "Sucess", msg: "Group successfuly removed." });
      });
    },
    addMembersToGroup() {
      for (let user in this.addUsers) {
          let data = {
            "group_id": this.groups[this.selectedRow].id,
            "user_id": useUsersStore().getUsers.find(element => this.addUsers[user] === element.username).id
          }
        console.log(data)
        axios_services.put("oidc/users/group",data).then(() => {
          this.addUsers = [];
          useGroupsStore().fetchGroups();
        });
      }
      eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Added members to group." });
    },
    removeMemberFromGroup(user) {
      axios_services.delete("oidc/users/" + user.id + "/group/" + this.groups[this.selectedRow].id).then(() => {
        useGroupsStore().fetchGroups();
        eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Removed member from group." });
      });
    }
  },

};
</script>

<style>
.selected {
  background-color: lightgrey;
}

.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: #f5f5f5;
}

.horizontalCenterCol {
  display: flex;
  align-items: center;
}
</style>
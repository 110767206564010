<template>
  <div>
    <v-card elevation="0" class="rounded-lg">
      <v-card-title>
        Recent Activities
      </v-card-title>

      <v-card-text>
        <v-select v-model="selectedFiles" :items="getFilesWithTimestamp" item-text="file_text" item-value="file_id" label="Select file name ..." 
          outlined dense multiple>
        </v-select>
      </v-card-text>

      <v-data-table :headers="headers" :items="filteredActivities" :items-per-page="10" :loading="activitiesDataLoading"
        loadig-text="Data loading..." class="elevation-1">
        <template v-slot:[`item.activity`]="{ item }">
          {{getFormattedActivityText(item.activity)}}
        </template>
        <template v-slot:[`item.timestamp`]="{ item }">
          {{item.activity_timestamp.slice(0, 19)}}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useMyFilesStore } from '@/stores/myfiles'
import axios_services from "@/axios-services";
import { useUsersStore } from '@/stores/users';

export default {
  name: "ActivitiesTable",
  data() {
    return {
      headers: [
        { text: 'File name', value: 'file_name', align: 'start', sortable: true },
        { text: 'Activity', value: 'activity', sortable: true },
        { text: 'Date', value: 'activity_timestamp', sortable: true }
      ],
      activitiesData: [],
      activitiesDataLoading: false,
      selectedFiles: []
    };
  },
  components: {
  },
  computed: {
    files() {
      return useMyFilesStore().files;
    },
    users() {
      return useUsersStore().users;
    },
    filteredActivities() {
      let filtered_activities;

      if (this.selectedFiles.length === 0) {
        filtered_activities = this.activitiesData;
        return filtered_activities;
      }
      else {
        filtered_activities = this.activitiesData.filter((item) => {
          if (this.selectedFiles.includes((item.file_id))) {
            return item;
          }
        });
        return filtered_activities;
      }
    },
    getFilesWithTimestamp() {
      return useMyFilesStore().getFilesWithTimestamp;
    }
  },
  mounted() {
    this.loadActivities();
  },
  methods: {
    loadActivities() {
      this.activitiesDataLoading = true;
      axios_services.get("activities").then((response) => {
        console.log(response.data.activities)
        this.activitiesData = response.data.activities;
      }).finally(() => {
        this.activitiesDataLoading = false;
      });
    },
    getFormattedActivityText(activityText) {
      const activityTextArr = activityText.split('__');

      const activitiesLookupTable = {
        "upload": "File uploaded",
        "download": "File downloaded",
        "shared_with_user": "File shared with User: ",
        "shared_with_group": "File shared with Group: ",
        "stopped_sharing_with_user": "Stopped sharing file with User: ",
        "stopped_sharing_with_group": "Stopped sharing file with Group: ",
        "delete": "File deleted",
      }; 
      const activityTextMapper = (activityText) => activitiesLookupTable[activityText] || activityText;

      if (activityTextArr.length === 1) {
        return activityTextMapper(activityTextArr[0]);
      } else if (activityTextArr.length === 2) {
        return activityTextMapper(activityTextArr[0]) + this.getUsername(activityTextArr[1]);
      } else {
        return activityText
      }
    },
    getUsername(userId) {
      let user = this.users.find(element => element.id === userId);
      if (user)
        return user.username;
      else
        return 'unknown (was deleted)';
    }
  },
  watch: {
  }
};
</script>

<style scoped>
.chip-shared:hover {
  cursor: pointer;
}
</style>
<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="40%" overlay="true">
      <div class="text-center" v-if="loading">
        <v-progress-circular :size="60" indeterminate> </v-progress-circular>
      </div>

      <v-card v-else>
        <v-card-title class="text-h5">
          Comments for {{ data.file_name }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-for="comment in comments" :key="comment.timestamp">
            <v-badge color="blue" :content="comment.timestamp.substring(0, 19)" bottom overlap style="width: 100%">
              <v-card label outlined class="mt-7" style="padding-top: 16px; padding-left: 16px; padding-right: 16px;" width="100%">
                <span class="blue--text text-body-1 font-weight-bold">{{ getOwnerName(comment.user_id) }}:</span>
                <v-icon v-if="comment.user_id === user_id" @click="deleteComment(comment.id)" v-on="on" style="float: right;">
                  mdi-delete
                </v-icon>
                <v-card-text style="text-align: justify;">{{ comment.comment }}</v-card-text>
              </v-card>
            </v-badge>
          </div>
          <v-textarea class="mt-7" label="New Comment" placeholder="Write your new comment" outlined v-model="text">
          </v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()">
            Close
          </v-btn>
          <v-btn color="primary" @click="send()" :disabled="text == ''">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios-services";
import { useUsersStore } from '@/stores/users';
import { useMyFilesStore } from '@/stores/myfiles';
import { useSharedFilesStore } from '@/stores/sharedfiles';
import { useAllFilesStore } from '@/stores/allfiles';
import { useUserStore } from '@/stores/user';

export default {
  name: "CommentDialog",
  data() {
    return {
      dialog: false,
      comments: null,
      data: null,
      text: "",
      loading: true,
      user_id: ""
    };
  },
  mounted() {
    eventBus.$on('toggle-comment-dialog', (data) => {
      this.loadData(data);
    });
  },
  methods: {
    send() {
      axios_services.post("comments", { file_id: this.data.file_id, comment: this.text, user_id: useUserStore().getUserID}).then(() => {
        this.loadData(this.data);
        useMyFilesStore().fetchMyFiles();
        useSharedFilesStore().fetchSharedFiles();
        useAllFilesStore().fetchAllFiles();
      });
    },
    getOwnerName(item) {
      var users = useUsersStore().getUsers;
      if (users.find(element => element.id === item)) {
        return users.find(element => element.id === item).username
      }
      else
        return item;
    },
    loadData(data) {
      this.loading = true;
      this.text = "";
      this.dialog = true;
      this.data = data;
      this.user_id = useUserStore().user_id;
      axios_services.get("comments/" + data.file_id).then((response) => { this.comments = response.data.comments; this.loading = false; });
    },
    close() {
      this.dialog = false;
    },
    deleteComment(commentId){
      axios_services.delete("comments/" + commentId).then(() => {
        this.loadData(this.data);
        useMyFilesStore().fetchMyFiles();
        useSharedFilesStore().fetchSharedFiles();
        useAllFilesStore().fetchAllFiles();
      })
    }

  },
  watch: {
  }
};
</script>

<style>

</style>

<style scoped>
::v-deep .v-badge__badge {
  inset: calc(100% - 12px) auto auto calc(100% - 110px) !important;
}

::v-deep .theme--light.v-sheet--outlined {
  border-color: #607d8b;
}

.v-progress-circular {
  margin: 1rem;
}
</style>

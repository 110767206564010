import {defineStore} from "pinia";
import axios_services from "./../axios-services";
import {useUserStore} from "@/stores/user";
import {useDownloadRate} from "@/stores/downloadrate";

export const useMyFilesStore = defineStore("myFiles", {
  state: () => {
    return {
      files: [],
      filesLoading: false,
      downloadRateReady: false
    };
  },
  getters: {
    getFilesWithTimestamp: (state) => {
      var x = [];
      state.files.forEach((element) => {
        x.push({
          file_id: element.file_id,
          file_name: element.file_name,
          file_text: element.file_name
        });
      });

      return x;
    },
    getFiles(state) {
      return state.files;
    },
    getDownloadRateReady(state) {
      return state.downloadRateReady;
    },
  },
  actions: {
    fetchMyFiles() {
      this.filesLoading = true;
      let files_tmp = [];
      console.log(useUserStore().user_id)
      axios_services
        .get("files/user/" + useUserStore().getUserID)
        .then((response) => {
          for (let file in response.data["files"]) {
            files_tmp.push(response.data["files"][file])
            files_tmp[file]['download_time'] = -1;
          }
          this.files = files_tmp;
          this.filesLoading = false;
          if(!useDownloadRate().downloadRate)
            this.updateDownloadTime();
        })
        .catch((error) => {
          console.log("error: cannot fetch data", error);
          this.filesLoading = false;
        });
    },
    updateDownloadTime() {
      this.downloadRateReady = false;
      useDownloadRate().calculateDownloadRate().then(() => {
        for (let index in this.files) {
          this.files[index]['download_time'] = this.files[index]['file_size'] / useDownloadRate().getDownloadRate;
        }
        let filesCopy = this.files;
        this.files = [];
        this.files = filesCopy;
        this.downloadRateReady = true;
      });
    }
  },
});

<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>

        <v-card-title class="text-h6 grey lighten-2 mb-4">
          Remove File: {{ fileName }}
        </v-card-title>

        <v-card-text>
          Are you sure you want to permanently remove this file?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            Close
          </v-btn>
          <v-btn text color="red" @click="removeFile()">
            Remove
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios-services";
import { useMyFilesStore } from '@/stores/myfiles';
import { useAllFilesStore } from '@/stores/allfiles';

export default {
  name: "DeleteFileDialog",
  data() {
    return {
      dialog: false,
      fileName: "",
      fileId: null
    };
  },
  components: {
  },
  mounted() {
    eventBus.$on('show-delete-file-dialog', (data) => {
      this.fileName = data.file_name;
      this.fileId = data.file_id;
      this.dialog = true;
    });
  },
  methods: {
    removeFile() {
      axios_services
        .delete('files/' + this.fileId)
        .then(() => {
          this.dialog = false;
          eventBus.$emit("show-snackbar", { type: "success", title: "Sucess", msg: "File successfuly removed." });
          useMyFilesStore().fetchMyFiles();
          useAllFilesStore().fetchAllFiles();
        })
        .catch((error) => {
          eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "File could not be removed." });
          console.log("error: cannot download data", error);
        });
    }
  }
};
</script>

<style scoped>

</style>
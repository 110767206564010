import { defineStore } from "pinia";
import axios_services from "./../axios-services";

export const useAllFilesStore = defineStore("allFiles", {
  state: () => {
    return {
      files: [],
      filesLoading: false,
    };
  },
  getters: {},
  actions: {
    fetchAllFiles() {
      this.filesLoading = true;
      axios_services
        .get("files")
        .then((response) => {
          this.files = response.data.files;
          this.filesLoading = false;
        })
        .catch((error) => {
          console.log("error: cannot fetch data", error);
          this.filesLoading = false;
        });
    },
  },
});

<template>
  <div>
   <Profile />
  </div>
</template>

<script>
import Profile from './Profile.vue';

export default {
  name: "ProfileIndex",
  data() {
    return {
    };
  },
  components: {
    Profile
},
  
};
</script>

<style scoped>
</style>
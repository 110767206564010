import { defineStore } from "pinia";
import axios_services from "@/axios-services";


export const useGroupsStore = defineStore("groups", {
  state: () => {
    return {
      groups: [],
    };
  },
  getters: {
    getGroups(state) {
      return state.groups;
    }
  },
  actions: {
    async fetchGroups() {
      axios_services.get("oidc/groups").then((response) => {
        this.groups = response.data.groups;
      });
    }
  },
});

import Vue from 'vue'
import Keycloak from 'keycloak-js'

const initOptions = {
    url: 'https://authorize.geoville.com/',
    realm: 'earth_receiver',
    clientId: 'Frontend',
};

const _keycloak = new Keycloak(initOptions)

const Plugin = {
    install: Vue => {
        Vue.$keycloak = _keycloak;
    },
};

Plugin.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
import { defineStore } from "pinia";
import axios_services from "@/axios-services";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user_id: null,
      email: null,
      familyName: null,
      firstName: null,
      groups: [],
      roles: [],
      username: null,
      contingent: null,
      used_gb: null,
    };
  },
  getters: {
    isAdmin(state) {
      return state.roles.includes("earth_receiver_administrator");
    },
    user(state) {
      return {
        user_id: state.user_id,
        username: state.username,
        email: state.email,
        first_name: state.firstName,
        family_name: state.familyName,
        groups: state.groups.toString(),
        roles: state.roles.toString(),
        contingent: state.contingent,
        used_gb: state.used_gb,
      };
    },
    profile(state) {
      return {
        user_id: state.user_id,
        username: state.username,
        email: state.email,
        first_name: state.firstName,
        family_name: state.familyName,
        groups: state.groups.map(obj => obj.name),
        roles: state.roles.toString(),
        contingent: state.contingent,
        used_gb: state.used_gb,
      };
    },
    getUserID(state) {
      return state.user_id;
    }
  },
  actions: {
    fetchUser() {
      axios_services.get("oidc/user_info/"+this.user_id).then((response) => {
        this.email = response.data["email"];
        this.firstName = response.data["given_name"];
        this.familyName = response.data["family_name"];
        this.groups = response.data["groups"];
        this.roles = response.data["roles"];
        this.username = response.data["username"];
        this.contingent = response.data["contingent"];
        this.used_gb = response.data["used_gb"];
      });
      },
    setUserID(user_id) {
      this.user_id = user_id;
    }
  },
});

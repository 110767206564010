<template>
  <div>
    <ShareFileDialog />
    <ShareViewDialog />
    <DeleteFileDialog />
    <MyFilesTable />
  </div>
</template>

<script>
import MyFilesTable from '@/views/my-files/MyFilesTable.vue'
import ShareFileDialog from '@/views/my-files/ShareFileDialog.vue'
import DeleteFileDialog from '@/views/my-files/DeleteFileDialog.vue'
import ShareViewDialog from './ShareViewDialog.vue';

export default {
  name: "MyFilesIndex",
  data() {
    return {
    };
  },
  components: {
    MyFilesTable,
    ShareFileDialog,
    DeleteFileDialog,
    ShareViewDialog
},
  methods: {
  }

};
</script>

<style scoped>
</style>

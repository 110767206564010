<template>
  <div>
   <UserTable />
  </div>
</template>

<script>
import UserTable from './UserTable.vue';

export default {
  name: "UsersIndex",
  data() {
    return {
    };
  },
  components: {
    UserTable
},
  
};
</script>

<style scoped>
</style>
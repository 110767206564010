<template>
  <v-data-table :headers="headers" :items="users" :items-per-page="20" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat class="rounded-lg">
        <v-toolbar-title>Users
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New User
            </v-btn>
          </template>
          <NewUser @close="close" @save="save" role="/roles/user" action="new" />
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-dialog v-model="dialogEdit[item.id]" max-width="600px" :retain-focus="false">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on : tooltip}">
              <v-icon small class="mr-2" v-bind="attrs" v-on="{...menu, ...tooltip}">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit user</span>
          </v-tooltip>
        </template>
        <NewUser @close="close" @save="save" :role="item.roles" action="update" :id="item.id" :contingent="item.contingent"
          :username="item.username" :firstName="item.given_name" :lastName="item.family_name" :email="item.email" />
      </v-dialog>
      <v-dialog v-model="dialogRemove[item.id]" max-width="600px" :retain-focus="false">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on : tooltip}">
              <v-icon small class="mr-2" v-bind="attrs" v-on="{...menu, ...tooltip}">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete user</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title class="text-h6 grey lighten-2">
            Remove User: {{ item.username }}
          </v-card-title>
          <v-card-text>Are you sure you want to permanently delete the user and all its respective data?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogRemove[item.id] = false">
              Close
            </v-btn>
            <v-btn color="error" text @click="deleteUser(item); dialogRemove[item.id] = false;">
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:[`item.contingent`]="{ item }">
      <v-progress-linear :value="(100 *item.used_gb / item.contingent)"
              color="blue-grey" height="25" striped rounded>
              <strong>{{ $helpers.formatGigaBytes(item.used_gb) }} /
                {{ $helpers.formatGigaBytes(item.contingent) }}</strong>
            </v-progress-linear>

    </template>
    <template v-slot:[`item.roles`]="{ item }">
      <v-chip color="blue-grey" label outlined>{{ item.roles }}</v-chip>

    </template>
  </v-data-table>
</template>
  
<script>
import axios_services from "@/axios-services";
import NewUser from './NewUser.vue';
import { useUsersStore } from '@/stores/users';
import { eventBus } from "@/main.js";

export default {
  name: "UserTable",
  components: {
    NewUser
  },
  data() {
    return {
      headers: [
        {
          text: 'Username',
          align: 'start',
          value: 'username',
        },
        {
          text: 'First Name',
          value: 'given_name',
        },
        { text: 'Last Name', value: 'family_name' },
        { text: 'Email', value: 'email' },
        { text: 'Storage', value: 'contingent', width:'15%' },
        { text: 'Role', value: 'roles' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      dialog: false,
      dialogEdit: {},
      dialogRemove: {},
      text: ""
    }
  },
  mounted() {
  },
  computed: {
    users() {
      return useUsersStore().getUsers;
    }
  },
  methods: {
    close(id) {
      if (id) {
        this.dialogEdit[id] = false;
      } else {
        this.dialog = false;
      }
    },
    save(id) {
      this.close(id);
      useUsersStore().fetchUsers();
    },
    deleteUser(user) {
      axios_services.delete("oidc/users/" + user.id).then(() => {
        axios_services.delete("contingents/" + user.id).then(() => {
          useUsersStore().fetchUsers();
          eventBus.$emit("show-snackbar", { type: "success", title: "Sucess", msg: "User successfuly removed." });
        })
      });
    },
  }
}
</script>
  
<style scoped>

</style>
<template>
  <div>
    <v-app-bar app clipped-left dark dense color="black" elevation="0">
      <!-- <v-toolbar-title class="pl-0" color="white">SatDrive</v-toolbar-title> -->
      
      <img :src="require('@/assets/logo_geoville_32px.png')" @click="visitWebpage('geoville')" class="icon mr-2" alt="GeoVille" height="32px">
      <img :src="require('@/assets/logo_earthStreamer_32px.png')" @click="visitWebpage('earthReceiver')" class="icon" alt="EarthReceiver" height="32px">

      <v-spacer></v-spacer>
      <v-btn icon v-on="on">
        <v-icon @click="showAboutDialog()">mdi-information-outline</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in items" :key="item.name" style="padding: 0px">
            <v-list-item-action style="margin: 0px"><v-btn @click="item.action" text >{{ item.name }}</v-btn></v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import { eventBus } from "@/main.js";

export default {
  name: "AppBar",
  components: {},
  data() {
    return {
      items: [
        { name: "Profile", action: this.goToProfile },  
        { name: "Logout", action: this.logout},
      ]
    }
  },
  methods: {
    goToProfile() {
      this.$router.push({path: "/profile"});
    },
    logout() {
      useAuthStore().logout();
    },
    visitWebpage(icon) {
      if (icon === 'geoville') {
        window.open("https://geoville.com");
      } else if (icon === 'earthReceiver') {
        window.open(window.location.href);
      }
    },
    showAboutDialog() {
      eventBus.$emit("show-about-dialog", true);
    }
  }
};
</script>

<style>
.icon:hover {
  cursor: pointer;
}
</style>
<template>
  <v-snackbar v-model="snackbar" top :color="type" :timeout="timeout">
    <strong class="mr-2">{{ title }}!</strong>{{ msg }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
  
<script>
import { eventBus } from '@/main.js';

export default {
  name: "NotificationSnackBar",
  components: {},
  data() {
    return {
      snackbar: false,
      timeout: 2500,
      type: "",
      title: "",
      msg: "",
    };
  },
  mounted() {
    eventBus.$on('show-snackbar', (data) => {
      this.type = data.type;
      this.title = data.title;
      this.msg = data.msg;
      this.snackbar = true;
    });
  },
  methods: {},
};
</script>
      
<style scoped>

</style>
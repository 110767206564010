<template>
  <div>
    <v-card elevation="0" class="rounded-lg">
      <v-card-title>
        Shared Files
      </v-card-title>
      <v-data-table :headers="headers" :items="sharedFiles" :items-per-page="15" :loading="sharedFilesLoading==true"
        loading-text="Data loading..." class="elevation-1"
      >

        <!-- size -->
        <template v-slot:[`item.file_size`]="{ item }">
          {{ formatBytes(item.file_size) }}
        </template>

        <!-- owner -->
        <template v-slot:[`item.owner`]="{ item }">
          {{ getOwnerName(item.owner) }}
        </template>

        <!-- comments -->
        <template v-slot:[`item.comments_count`]="{ item }">
          <v-btn icon @click="comment(item)" >
          <v-badge
          color="blue"
          :content="item.comment_count.toString()"
          overlap
          bordered
        >
        <v-icon class="">
            mdi-message-text-outline
          </v-icon>
        </v-badge>
          </v-btn>
        </template>

        <!-- comment 
        <template v-slot:[`item.comments_count`]="{ item }">
          <div v-if="item.comments_count > 0">
            <v-chip class="chip-shared" small @click="showCommentDetails(item)">
              <v-icon class="mr-2" small>mdi-comment-processing-outline</v-icon>Comments
            </v-chip>
          </div>
          <div v-else>
          </div>
        </template> -->

        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top v-if="!isDownloading || !(selectedItem == item.file_id)" :key="isDownloading">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-3 ml-3" @click="downloadFile(item)" v-on="on">
                mdi-download {{ item }}
              </v-icon>
            </template>
            <span>Download file</span>
          </v-tooltip>

          <v-tooltip top v-if="isDownloading && (selectedItem == item.file_id)" :key="isDownloading">
            <template v-slot:activator="{ on }">
              <v-progress-circular class="mr-3 ml-3" :size="20" indeterminate color="primary" v-on="on"></v-progress-circular>
            </template>
            <span>Downloading file</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useSharedFilesStore } from '@/stores/sharedfiles'
import { eventBus } from "@/main.js";
import { useUsersStore } from '@/stores/users';
import { useDownloadingStore } from '@/stores/downloading';
import fileDownloadMixin from '@/mixins/file_download_mixin.js';

export default {
  name: "SharedFilesTable",
  mixins: [fileDownloadMixin],
  data() {
    return {
      headers: [
        { text: 'File name', value: 'file_name', align: 'start', sortable: true },
        { text: 'Shared by', value: 'owner', sortable: true,},
        { text: 'Size', value: 'file_size', sortable: true },
        { text: 'Comments', value: 'comments_count', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  components: {
  },
  computed: {
    sharedFiles() {
      console.log(useSharedFilesStore().files)
      return useSharedFilesStore().files;
    },
    sharedFilesLoading() {
      return useSharedFilesStore().filesLoading;
    },
    isDownloading() {
      return useDownloadingStore().downloading;
    }
  },
  mounted() {
    useSharedFilesStore().fetchSharedFiles();
    useDownloadingStore().downloading = false;
  },
  methods: {
    downloadFile(item) {
      if(!useDownloadingStore().downloading){
        this.selectedItem = item.file_id
        this.download(item); // called from mixin object
      }else{
        eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "Wait for the other file to finish downloading."});
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))
      
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    getOwnerName(item) {
      var users = useUsersStore().users;
      let owner = users.find(element => element.id == item);
      if (owner) {
        return owner.username
      } else {
        return "unknown user"
      }
      
    },
    comment(item) {
      eventBus.$emit("toggle-comment-dialog", item);
    },
  }

};
</script>

<style scoped>
.chip-shared:hover {
  cursor: pointer;
}
</style>

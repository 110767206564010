<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Group Profile</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-text-field label="Name*" :rules="requiredRule" v-model="name"></v-text-field>
          <v-autocomplete v-model="addUsers" :items="users" hide-details="auto" clearable hide-no-data
            color="blue-grey lighten-2" label="Members" item-text="username" item-value="username" multiple
            prepend-icon="mdi-account-multiple-plus">
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                @click:close="remove(data.item)">
                {{ data.item.username }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.username"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-container>
      </v-form>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">
        Close
      </v-btn>
      <v-btn color="blue darken-1" text @click="save" :disabled="!valid">
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
  
<script>
import axios_services from "@/axios-services";
import { eventBus } from "@/main.js";
import {useUsersStore} from "@/stores/users";

export default {
  name: "NewGroup",
  data() {
    return {
      name: "",
      valid: true,
      requiredRule: [v => !!v || 'Required'],
      addUsers: [],
    }
  },
  mounted() {
  },
  computed: {
    users() {
      return useUsersStore().getUsers;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      let data = {
        "group_name": this.name,
      }
      axios_services.post("oidc/groups", data).then((response) => {
        console.log(response.data)
        for (let user in this.addUsers) {
          let data = {
            "group_id": response.data.group_id,
            "user_id": useUsersStore().getUsers.find(element => this.addUsers[user] === element.username).id
          }
          axios_services.put("oidc/users/group", data).then(() => {
          });
        }
        this.$emit('save');
        eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "Created group." });
      }).catch(() => {
        eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "Creating group failed. Group name already exists." });
        this.close();
      });
    },
    remove(item) {
      const index = this.addUsers.indexOf(item.username)
      if (index >= 0) this.addUsers.splice(index, 1)
    },
  },
}
</script>
  
<style scoped>

</style>
import { defineStore } from "pinia";
import axios_services from "@/axios-services";

export const useUsersStore = defineStore("users", {
  state: () => {
    return {
      users: [],
    };
  },
  getters: {
    getUsers(state) {
      return state.users;
    }
  },
  actions: {
    fetchUsers() {
      axios_services.get("oidc/users_info").then((response) => {
        this.users = response.data.users;
      });
    }
  },
});

<template>
  <div>
    <ShareFileDialog />
    <ShareViewDialog />
    <DeleteFileDialog />
    <AllFilesTable />
  </div>
</template>

<script>
import ShareFileDialog from '@/views/my-files/ShareFileDialog.vue'
import DeleteFileDialog from '@/views/my-files/DeleteFileDialog.vue'
import ShareViewDialog from '@/views/my-files/ShareViewDialog.vue';
import AllFilesTable from './AllFilesTable.vue';

export default {
  name: "AllFilesIndex",
  data() {
    return {
    };
  },
  components: {
    ShareFileDialog,
    DeleteFileDialog,
    ShareViewDialog,
    AllFilesTable
},
  methods: {
  }

};
</script>

<style scoped>
</style>

<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          File {{ fileName }} shared with:
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab href="#user-tab">
              Share with users
            </v-tab>
            <v-tab href="#group-tab">
              Share with groups
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="user-tab">
              <v-card flat>
                <v-data-table :headers="headersUsers" :items="allowed_users" :items-per-page="10" class="elevation-1" no-data-text="There are currently no users that you shared the file with.">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="removeUser(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="2" value="group-tab">
              <v-card flat>
                <v-data-table :headers="headersGroups" :items="allowed_groups" :items-per-page="10" class="elevation-1" no-data-text="There are currently no groups that you shared the file with.">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small @click="removeGroup(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import { useUsersStore } from '@/stores/users';
import { useGroupsStore } from '@/stores/groups';
import axios_services from "@/axios-services";
import { useMyFilesStore } from '@/stores/myfiles';
import { useAllFilesStore } from '@/stores/allfiles';

export default {
  name: "ShareViewDialog",
  data() {
    return {
      dialog: false,
      fileName: "",
      allowed_users: [],
      allowed_groups: [],
      groups: [],
      file_id: null,
      tab: "user-tab",
      headersUsers: [
        { text: 'User', value: 'username', align: 'start', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersGroups: [
        { text: 'Group', value: 'groupname', align: 'start', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  components: {
  },
  computed: {
  },
  mounted() {
    eventBus.$on('show-shared-view-dialog', (data) => {
      this.loadData(data);
    });
  },
  methods: {
    removeUser(user) {
      return axios_services.delete("sharing/user/" + user.id + "/" + this.file_id).then(() => {
        this.allowed_users = this.allowed_users.filter((item) => item.id !== user.id);
        useMyFilesStore().fetchMyFiles();
        useAllFilesStore().fetchAllFiles();
      });
    },
    removeGroup(group) {
      return axios_services.delete("sharing/group/" + group.id + "/" + this.file_id, ).then(() => {
        this.allowed_groups = this.allowed_groups.filter((item) => item.id !== group.id);
        useMyFilesStore().fetchMyFiles();
        useAllFilesStore().fetchAllFiles();});
    },
    getOwnerName(item) {
      return useUsersStore().users.find(element => element.id === item).username;
    },
    getGroupName(item) {
      return useGroupsStore().groups.find(element => element.id === item).name;
    },
    loadData(data) {
      this.dialog = true;
      var allowed_users = [];
      for (var user in data.allowed_users) {
        allowed_users.push({id: data.allowed_users[user], username: this.getOwnerName(data.allowed_users[user])})
      }
      this.allowed_users = allowed_users;
      var allowed_groups = [];
      for (var group in data.allowed_groups) {
        allowed_groups.push({id: data.allowed_groups[group], groupname: this.getGroupName(data.allowed_groups[group])})
      }
      this.allowed_groups = allowed_groups;
      this.fileName = data.name;
      this.tab = "users-tab";
      this.file_id = data.file_id;
    }
  }
};
</script>

<style scoped>

</style>

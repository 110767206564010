<template>
  <div>
   <ActivitiesTable />
  </div>
</template>

<script>
import ActivitiesTable from "@/views/recent/ActivitiesTable.vue"

export default {
  name: "RecentIndex",
  data() {
    return {
    };
  },
  components: {
    ActivitiesTable
  },
  mounted() {},
};
</script>

<style scoped>
</style>
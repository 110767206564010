<template>
  <GroupsTable />
</template>

<script>
import GroupsTable from './GroupsTable.vue';

export default {
  name: "GroupIndex",
  data() {
    return {
    };
  },
  components: {
    GroupsTable,
  },
  methods: {
  },
  
};
</script>

<style scoped>
</style>
<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="80%" overlay="true">
      <v-card>
        <v-card-title class="text-h5">
          Upload Files
          <v-spacer></v-spacer>
          <v-btn :disabled="uploading" icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div v-if="showError">
            <v-alert color="orange" type="error">
              Uploading a folder is not supported. Please select individual files instead.
            </v-alert>
          </div>

          <v-container v-if="uploadedFiles.length === 0 && Object.keys(overwriteFiles).length === 0" id="upload-box" fluid fill-height
                       @dragover.prevent @drop.prevent="onDrop($event)"
                       @dragenter.prevent.stop="dragOn" @drop.prevent.stop="dragOff" @dragleave.prevent.stop="dragOff"
                       @click="showUploadDialog()" :class="{ 'upload-box-dragged': dragover }">
            <v-row class="text-center align-center justify-center mt-2 mb-2" style="pointer-events: none">
              <div v-if="uploadedFiles.length === 0 && Object.keys(overwriteFiles).length === 0">
                <v-icon size="60">mdi-cloud-upload</v-icon>
                <div class="text-subtitle-1">Drag files here or click to open the upload dialog</div>
              </div>
              <div v-else :key="forceRerenderItemsList">
                 <!-- First time uploaded files-->
                <div v-for="(item, index) in uploadedFiles" :key="item.id">
                  <span class="text-subtitle-1">{{ uploadedFiles[0].name }}</span>
                  <span class="ml-2"><v-icon @click="deleteItem(index)" class="trash">mdi-delete</v-icon></span>
                  <br/>
                </div>
                <!-- Files to overwrite-->
                <div v-for="(file, index) in overwriteFiles" :key="index">
                  <span class="text-subtitle-1">{{ file.name }}</span>
                  <span class="ml-2"><v-icon @click="delete overwriteFiles[index]; forceRerenderItemsList = !forceRerenderItemsList;" class="trash">mdi-delete</v-icon></span>
                  <br/>
                </div>
              </div>
            </v-row>
            <form ref="form">
              <input type="file" id="file-upload-placeholder" ref="uploadFile" @change="onFileChange" multiple/>
            </form>
          </v-container>

          <v-container v-else id="upload-box-with-file" fluid fill-height 
                  @dragover.prevent @drop.prevent="onDrop($event)"
                       @dragenter.prevent.stop="dragOn" @drop.prevent.stop="dragOff" @dragleave.prevent.stop="dragOff" :class="{ 'upload-box-dragged': dragover }">
            <div v-if="uploading" style="width: 30% !important">
              <div class="text-center pb-1">Uploading</div>
              <v-progress-linear
                v-model="uploadProgressPercent"
                height="25"
                class="rounded"
                color="blue lighten-1"
                background-color="blue lighten-5"
              >
                <strong v-if="uploadProgressPercent<100">{{ uploadProgressPercent }}%</strong>
                <strong class="appendMovingDots" v-else>Finalizing upload</strong>
              </v-progress-linear>
            </div>
            <div v-else>
              <div class="text-center align-center justify-center mt-2 mb-2" :key="forceRerenderItemsList">
                <!-- First time uploaded files-->
                <div v-for="(item, index) in uploadedFiles" :key="item.id">
                  <span class="text-subtitle-1">{{ uploadedFiles[index].name }}</span>
                  <span class="ml-2"><v-icon @click="deleteItem(index)" class="trash">mdi-delete</v-icon></span>
                  <br/>
                </div>
                <!-- Files to overwrite-->
                <div v-for="(file, index) in overwriteFiles" :key="index">
                  <span class="text-subtitle-1">{{ file.name }}</span>
                  <span class="ml-2"><v-icon @click="delete overwriteFiles[index]; forceRerenderItemsList = !forceRerenderItemsList;" class="trash">mdi-delete</v-icon></span>
                  <br/>
                </div>
              </div>
            </div>
            <form ref="form">
              <input type="file" id="file-upload-placeholder" ref="uploadFile" @change="onFileChange" multiple/>
            </form>
          </v-container>
        </v-card-text>

        <v-card-text>
          <v-text-field dense v-model="uploadComment" label="Add a comment (optional)" outlined></v-text-field>
          <v-row justify="end" class="pr-3">
            <v-checkbox v-model="compress" label="Compress" class="my-0 py-0"></v-checkbox>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false" :disabled="uploading">
            Close
          </v-btn>
          <v-btn color="primary" @click="prepareFileUpload()" :disabled="(this.showError) || (this.uploadedFiles.length === 0 && Object.keys(this.overwriteFiles).length === 0) || (uploading)">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Duplicate actions file dialog-->
    <v-dialog v-model="showDuplicateFilesDialog" style='z-index:20001;' max-width="35%" overlay="true" persistent>
      <v-card title="Dialog">
        <v-card-title class="text-h5">
          File duplicates exist
        </v-card-title>
        <v-card-text>
          Remove the file(s) from the upload or overwrite the existing one(s).
        </v-card-text>
        <v-container :key="forceDuplicateRerender">
          <div v-for="(file, index) in duplicateFiles" :key="index" class="d-flex ml-3">
            <span class="mr-auto text-subtitle-1">{{ file.name }}</span>
            <v-btn @click="addToOverwrite(file, index)" small plain>Overwrite</v-btn>
            <v-btn @click="fileToRename = index; showRenameDuplicateDialog = true;" small plain>Rename</v-btn>
            <v-btn @click="deleteDuplicate(index)" small plain>Don't upload</v-btn>
            <br/>
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false; showDuplicateFilesDialog=false;">
            Abort file upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Rename file dialog-->
    <v-dialog v-model="showRenameDuplicateDialog" style='z-index:20002;' max-width="515px" overlay="true" persistent>
      <v-card>
        <v-card-title class="text-h5">
          Rename file
        </v-card-title>
        <v-card-text>
          Fill in a file name which does not already exist.
        </v-card-text>
        <v-container>
          <v-text-field
            class="ml-3"
            label="Name"
            hint="Do not forget the file extension (.txt, .png, etc.)"
            v-model="filenameForRename"
          ></v-text-field>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showRenameDuplicateDialog = false">
            Abort
          </v-btn>
          <v-btn color="primary" @click="renameFileDuplicate()" :disabled="filenameForRename === ''">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";
import axios_services from "./../axios-services";
//import {useMyFilesStore} from '@/stores/myfiles'
//import {useSharedFilesStore} from '@/stores/sharedfiles'
import {useUserStore} from "@/stores/user";
import {useMyFilesStore} from "@/stores/myfiles";
import {useSharedFilesStore} from "@/stores/sharedfiles";

export default {
  name: "NewFileDialog",
  data() {
    return {
      dialog: false,
      dragover: false,
      uploadedFiles: [],
      overwriteFiles: {},
      forceDuplicateRerender: 0,
      forceRerenderItemsList: false,
      uploadComment: "",
      uploading: false,
      uploadProgress: 0,
      uploadSize: 0,
      showError: false,
      compress: false,
      showDuplicateFilesDialog: false,
      showRenameDuplicateDialog: false,
      duplicateFiles: {},
      fileToRename: null,
      filenameForRename: ""
    };
  },
  mounted() {
    eventBus.$on('toggle-new-file-dialog', (data) => {
      this.dialog = data;
      this.showError = false;
      this.overwriteFiles = {};
      this.uploadedFiles = [];
      this.compress = false;
      this.duplicateFiles = {};
      this.showDuplicateFilesDialog = false;
      this.showRenameDuplicateDialog = false;
      this.uploadComment = "";
      this.uploadProgress = 0;
      this.uploadSize = 0;
      this.fileToRename = null;
      this.filenameForRename = "";
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    });
  },
  computed: {
    uploadProgressPercent() {
      return (Math.floor(this.uploadProgress/this.uploadSize*100 * 100) / 100).toFixed(2);
    },
  },
  methods: {
    dragOn() {
      this.dragover = true;
    },
    dragOff() {
      this.dragover = false;
    },
    onDrop(e) {
      this.showError = false;

      let files = e.dataTransfer.files;
      if (!files.length)
        return;

      let filesContainDir = false;
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i])
        let isDirectory = false;
        let reader = new FileReader();
        //check if file or directory
        reader.onerror = function () {
          isDirectory = true;
        }
        reader.readAsText(files[i]);
        if (isDirectory) {
          filesContainDir = true;
        }
      }

      if (filesContainDir) {
        this.showError = true;
        this.uploadedFiles = []
      }
    },
    showUploadDialog() {
      if (this.uploadedFiles.length === 0) {
        this.$refs.uploadFile.click();
      }
    },
    onFileChange(e) {
      this.showError = false;

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      let filesContainDir = false;
      for (let i = 0; i < files.length; i++) {
        this.uploadedFiles.push(files[i])
        let isDirectory = false;
        let reader = new FileReader();
        //check if file or directory
        reader.onerror = function () {
          isDirectory = true;
        }
        reader.readAsText(files[i]);
        if (isDirectory) {
          filesContainDir = true;
        }
      }

      if (filesContainDir) {
        this.showError = true;
        this.uploadedFiles = []
      }
    },
    addToOverwrite(file, index){
      this.overwriteFiles[index] = file;
      delete this.duplicateFiles[index];
      this.forceDuplicateRerender += 1;
      if(Object.keys(this.duplicateFiles).length == 0) this.showDuplicateFilesDialog = false;
    },
    async prepareFileUpload() {
      this.duplicateFiles = {};
      let result = await this.checkFileDuplicatesExist();
      this.uploadedFiles = this.uploadedFiles.filter(file => {
          let fileExists = result.filter(f => file.name == f.file_name && f.exists)
          if(fileExists.length >0){
            this.duplicateFiles[fileExists[0].file_id] = file;
            return false;
          }
          return true;
        })
      if(Object.keys(this.duplicateFiles).length < 1){
        this.uploadFile();
      }else{
        this.showDuplicateFilesDialog = true;
      }
    },
    async uploadFile() {
      this.showDuplicateFilesDialog = false;
      const files = [];
      const comment = this.uploadComment;
      this.uploading = true;
      try {
        let size = 0;
        const initialUploadFormData = new FormData();
        const overwriteFormData = new FormData();

        for (const file of this.uploadedFiles) {
          initialUploadFormData.append('documents', file);
            size += file.size;
        }
        Object.values(this.overwriteFiles).forEach(file => {
          overwriteFormData.append('documents', file);
          size += file.size;
        });
        this.uploadSize = size;
        if(this.uploadedFiles.length>0){
          const headers = { 'Content-Type': 'multipart/form-data', params: {'compress': this.compress}, onUploadProgress: progressEvent => this.uploadProgress=progressEvent.loaded };
          const response = await axios_services.post("files", initialUploadFormData, headers);
          if(response.data.files.length >0) files.push(response.data.files);
        }
        let tmpProgress = this.uploadProgress;
        if(Object.keys(this.overwriteFiles).length > 0){
          const fileIds = Object.keys(this.overwriteFiles)
          const oHeaders = { 'Content-Type': 'multipart/form-data', params: {'compress': this.compress, 'file_ids': fileIds}, onUploadProgress: progressEvent => this.uploadProgress=progressEvent.loaded+tmpProgress }
          const overwrittenFileResponse = await axios_services.post("files/overwrite", overwriteFormData, oHeaders);
          if(overwrittenFileResponse.data.files.length >0) files.push(overwrittenFileResponse.data.files)
        }
        if (comment) {
          const res = await this.sendComments(files , comment);
          this.handleUploadResult(res);
        } else {
          this.handleUploadResult(true);
        }
      } catch (error) {
        console.log("error: cannot upload data", error);
        this.uploading = false;
      }
    },
    async sendComments(files, comment) {
      // Create an array of promises
      let promiseArray = [];
      for (let i = 0; i < files.length; i++) {
        let payload = {
          file_id: files[i].file_id,
          comment: comment,
          user_id: useUserStore().getUserID
        };

        const p = new Promise((resolve, reject) => {
          axios_services.post("comments", payload).then(() => {
            resolve("success");
          }).catch((error) => {
            reject(error);
          });
        });
        promiseArray.push(p);
      }

      return await Promise.allSettled(promiseArray).then((res) => {
        let success = [];

        for (let i in res) {
          if (res[i].status === 'fulfilled') {
            success.push(true);
          } else {
            success.push(false);
          }
        }

        let successCount = success.filter(function (value) {
          return value === true;
        }).length

        return successCount === success.length;
      })
    },
    handleUploadResult(success) {
      this.uploading = false;
      this.dialog = false;

      const msg = success ?
        (this.uploadedFiles.length === 1 ? "File successfully uploaded." : "Files successfully uploaded.")
        : "File(s) uploaded, but comments could not be submitted.";

      const type = success ? "success" : "error";
      const title = success ? "Success" : "Error";

      eventBus.$emit("show-snackbar", { type, title, msg });

      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      useMyFilesStore().fetchMyFiles();
      useSharedFilesStore().fetchSharedFiles();
    },
    deleteItem(index) {
      this.uploadedFiles.splice(index, 1);
    },
    deleteDuplicate(index) {
      delete this.duplicateFiles[index];
      this.forceDuplicateRerender += 1;
      if(Object.keys(this.duplicateFiles).length == 0) this.showDuplicateFilesDialog = false;
    },
    async checkFileDuplicatesExist(){
      let fileNames = [];
      for(const file of this.uploadedFiles){
        fileNames.push(file.name);
      }
      return await axios_services.get("files/exist", { params: {'file_names': fileNames} }).then(response => {
        return response.data.files_exist;
      }).catch(error=>{
        console.log(error)
      })
    },
    renameFileDuplicate() {
      this.uploadedFiles.push(new File([this.duplicateFiles[this.fileToRename]], this.filenameForRename , {type: this.duplicateFiles[this.fileToRename].type, lastModified: this.duplicateFiles[this.fileToRename].lastModified}));
      delete this.duplicateFiles[this.fileToRename];
      this.forceDuplicateRerender += 1;
      this.showRenameDuplicateDialog = false;
      if(Object.keys(this.duplicateFiles).length == 0) this.showDuplicateFilesDialog = false;
    }
  },
  watch: {
    dialog() {
      this.uploadedFiles = [];
      this.uploadComment = "";
    },
  }
};
</script>

<style scoped>
/* #file-upload-box:hover {
  cursor: pointer;
}

#file-upload-box-empty {
  width: 100%;
  height: 100%;
  position: absolute;
} */

#upload-box {
  height: 250px;
  border: 1px dashed lightgray;
  overflow-y: scroll;
}

#upload-box-with-file {
  height: 250px;
  overflow-y: scroll;
  border: 1px dashed lightgray;
  justify-content: center;
}

#upload-box:hover {
  cursor: pointer;
}

.upload-box-dragged {
  background: #eeeeee;
}

#file-upload-placeholder {
  display: none;
}

.trash:hover {
  color: #1976d2 !important;
}

.appendMovingDots:after {
    content: ' .';
    animation: dots 2s steps(1, end) infinite;
}
@keyframes dots {
    0%, 20% {
      opacity: 1;
    }
    40% {
      text-shadow: .5em 0; 
    }
    60% {
      text-shadow: .5em 0, 1em 0;
    }
    90%, 100% {
      opacity: 0;
    }
  }
</style>

<template>
  <div>
    <v-navigation-drawer app left permanent :style="{ top: $vuetify.application.top + 'px', zIndex: 6 }"
                         color="#F4F5FA">
      <v-list-item class="pt-2 pb-0">
        <v-btn color="primary " elevation="2" block dark @click="addNewFile()">
          <v-icon class="pr-2">mdi-plus-circle-outline</v-icon>
          Add Files
        </v-btn>
      </v-list-item>

      <v-list dense rounded>
        <div v-for="item in items" :key="item.title">
          <v-list-item :to="item.path" link v-if="!item.requiredAdmin || isAdmin">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
      <v-list-item class="pb-0" two-line>
        <v-list-item-content>
          <v-list-item-title> Contingent:</v-list-item-title>
          <v-progress-linear :value="(100 * user.used_gb / user.contingent)"
                             color="blue-grey" height="25" striped rounded>
            <strong>{{ $helpers.formatGigaBytes(user.used_gb) }} /
              {{ $helpers.formatGigaBytes(user.contingent) }}</strong>
          </v-progress-linear>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {useUserStore} from '@/stores/user'
import {eventBus} from "@/main.js";

export default {
  name: "NavigationDrawer",
  components: {},
  data() {
    return {
      items: [
        {title: "My Files", icon: "mdi-file", path: "/", requiredAdmin: false},
        {title: "Shared Files", icon: "mdi-share-variant", path: "/shared-with-me", requiredAdmin: false},
        {title: "All Files", icon: "mdi-file-multiple", path: "/all-files", requiredAdmin: true},
        {title: "Recent", icon: "mdi-history", path: "/recent", requiredAdmin: false},
        {title: "Users", icon: "mdi-account", path: "/users", requiredAdmin: true},
        {title: "Groups", icon: "mdi-account-multiple", path: "/groups", requiredAdmin: true},
      ],
    }
  },
  computed: {
    isAdmin() {
      return useUserStore().isAdmin;
    },
    user() {
      return useUserStore().user;
    }
  },
  methods: {
    addNewFile() {
      eventBus.$emit("toggle-new-file-dialog", true);
    },
  },

};
</script>

<style scoped>

</style>
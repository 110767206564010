<template>
  <div>
    <v-dialog
      v-model="isDownloading"
      style="z-index: 20002"
      max-width="515px"
      overlay="true"
      persistent
      :key="isDownloading"
    >
      <v-card>
        <v-container>
          <div class="text-center mb-1">Downloading compressed file</div>
          <v-progress-linear
            v-model="downloadProgressPercent"
            height="25"
            class="rounded"
            color="blue lighten-1"
            background-color="blue lighten-5"
          >
            <strong v-if="downloadProgressPercent < 100"
              >{{ downloadProgressPercent }}%</strong
            >
            <strong v-else>Decompressing</strong>
          </v-progress-linear>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useDownloadingStore } from "@/stores/downloading";

export default {
  name: "DownloadDialog",
  data() {
    return {};
  },
  computed: {
    isDownloading() {
      return useDownloadingStore().downloading;
    },
    downloadProgressPercent() {
      return (
        Math.floor(
          (useDownloadingStore().downloadProgress /
            useDownloadingStore().downloadSize) *
            100 *
            100
        ) / 100
      ).toFixed(2);
    },
  },
};
</script>

<style scoped></style>

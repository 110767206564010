import Vue from "vue";
import VueRouter from "vue-router";
import GroupIndex from '@/views/groups'
import MyFilesIndex from '@/views/my-files'
import RecentIndex from '@/views/recent'
import SharedFilesIndex from '@/views/shared-files'
import UsersIndex from '@/views/users'
import ProfileIndex from '@/views/profile'
import AllFilesIndex from '@/views/all-files'
import PageNotFound from '@/components/PageNotFound.vue'
import pinia from "@/stores/store.js";
import { useUserStore } from '@/stores/user'

Vue.use(VueRouter);

export const routes = [
  { name: 'my-files', path: '/', component: MyFilesIndex, meta: {requiresAdmin: false}},
  { name: 'all-files', path: '/all-files', component: AllFilesIndex, meta: {requiresAdmin: true}},
  { name: 'shared-with-me', path: '/shared-with-me', component: SharedFilesIndex, meta: {requiresAdmin: false}},
  { name: 'recent', path: '/recent', component: RecentIndex, meta: {requiresAdmin: false} },
  { name: 'users', path: '/users', component: UsersIndex, meta: {requiresAdmin: true}},
  { name: 'groups', path: '/groups', component: GroupIndex, meta: {requiresAdmin: true}},
  { name: 'profile', path: '/profile', component: ProfileIndex, meta: {requiresAdmin: false}},
  { path: "*", component: PageNotFound, meta: {requiresAdmin: false} },
];

const router = new VueRouter({ mode: "history", routes });

router.beforeEach((to, from, next) => {
  const userStore = useUserStore(pinia );
  if(to.meta.requiresAdmin && !userStore.isAdmin) {
    next({path: from.path})
  } else {
    next();
  }
})

export default router;

<template>
  <div>
    <v-card elevation="0" class="rounded-lg">
      <v-card-title>
        All Files
      </v-card-title>
      <v-data-table :headers="headers" :items="myFiles" :items-per-page="10" :loading="myFilesLoading == true"
        loading-text="Data loading..." class="elevation-1">
        <!-- timestamp -->
        <template v-slot:[`item.timestamp`]="{ item }">
          {{ item.timestamp.slice(0, 19) }}
        </template>

        <!-- size -->
        <template v-slot:[`item.file_size`]="{ item }">
          {{ $helpers.formatBytes(item.file_size) }}
        </template>

        <!-- owner -->
        <template v-slot:[`item.owner`]="{ item }">
          {{ getOwnerName(item.owner) }}
        </template>

        <!-- comments -->
        <template v-slot:[`item.comments_count`]="{ item }">
          <v-btn icon @click="comment(item)">
            <v-badge color="blue" :content="item.comment_count.toString()" overlap bordered>
              <v-icon class="">
                mdi-message-text-outline
              </v-icon>
            </v-badge>
          </v-btn>
        </template>

        <!-- shared -->
        <template v-slot:[`item.allowed_user`]="{ item }">
          <div v-if="item.allowed_users.length > 0 || item.allowed_groups.length > 0">
            <v-chip class="chip-shared" small @click="showSharedDetails(item)">
              <v-icon class="mr-2" small>mdi-share-variant</v-icon>Shared
            </v-chip>
          </div>
          <div v-else>
          </div>
        </template>

        <!-- actions -->
        <template v-slot:[`item.actions`]="{ item }">

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small @click="shareFile(item)" v-on="on">
                mdi-share-variant {{ item }}
              </v-icon>
            </template>
            <span>Share file</span>
          </v-tooltip>

          <v-tooltip top v-if="!isDownloading || !(selectedItem == item.file_id)" :key="isDownloading">
            <template v-slot:activator="{ on }">
              <v-icon small class="mr-3 ml-3" @click="downloadFile(item)" v-on="on">
                mdi-download {{ item }}
              </v-icon>
            </template>
            <span>Download file</span>
          </v-tooltip>

          <v-tooltip top v-if="isDownloading && (selectedItem == item.file_id)" :key="isDownloading">
            <template v-slot:activator="{ on }">
              <v-progress-circular class="mr-3 ml-3" :size="20" indeterminate color="primary" v-on="on"></v-progress-circular>
            </template>
            <span>Downloading file</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small @click="deleteFile(item)" v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete file</span>
          </v-tooltip>

        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import { useAllFilesStore } from '@/stores/allfiles'
import { useUsersStore } from '@/stores/users';
import fileDownloadMixin from '@/mixins/file_download_mixin.js'
import { useDownloadingStore } from '@/stores/downloading';

export default {
  name: "MyFilesTable",
  mixins: [fileDownloadMixin],
  data() {
    return {
      headers: [
        { text: 'File name', value: 'file_name', align: 'start', sortable: true },
        { text: 'Owner', value: 'owner', sortable: true },
        { text: 'Uploaded at', value: 'timestamp', sortable: true, name: 'uploaded_at' },
        { text: 'Size', value: 'file_size', sortable: true },
        { text: 'Shared', value: 'allowed_user', sortable: false },
        { text: 'Comments', value: 'comments_count', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selectedItem: -1,
    };
  },
  components: {
  },
  computed: {
    myFiles() {
      return useAllFilesStore().files;
    },
    myFilesLoading() {
      return useAllFilesStore().filesLoading;
    },
    isDownloading() {
      return useDownloadingStore().downloading;
    }
  },
  mounted() {
    useAllFilesStore().fetchAllFiles();
    useDownloadingStore().downloading = false;
  },
  methods: {
    showSharedDetails(item) {
      console.log(item)
      eventBus.$emit("show-shared-view-dialog", item)
    },
    shareFile(item) {
      console.log("share file");
      eventBus.$emit("show-share-file-dialog", item);
    },
    downloadFile(item) {
      if(!useDownloadingStore().downloading){
        this.selectedItem = item.file_id
        this.download(item);  // called from mixin object
      }else{
        eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "Wait for the other file to finish downloading."});
      }
    },
    deleteFile(item) {
      eventBus.$emit("show-delete-file-dialog", item);
    },
    comment(item) {
      eventBus.$emit("toggle-comment-dialog", item);
    },
    getOwnerName(item) {
      var users = useUsersStore().users;
      let owner = users.find(element => element.id === item);
      if (owner) {
        return owner.username
      } else {
        return "unknown user"
      }
    },
  }

};
</script>

<style scoped>
.chip-shared:hover {
  cursor: pointer;
}
</style>
<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="text-h5">
          Earth Receiver
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <p>
            Welcome to EarthReceiver
            <br/>
EarthReceiver provides you data-transfer to remote regions without internet-connectivity. By utilizing a geostationary communication satellite fleet, operated by Eutelsat, you can exchange data where conventional solutions will not work.

EarthReceiver gives you full control over what you share with whom. A comprehensive usage-history and comment-system completes the solution.

If you want to disseminate complete imagery archives via low bandwidth connections, ask for our unique EarthStreamer solution.
          </p>
          <div style="margin: 25px 0 5px 0;"></div>
          <v-layout justify-center align-center mt-8>
            <img :src="require('@/assets/test.png')" alt="GeoVille" height="40px">
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "AboutDialog.vue",
  components: {},
  data() {
    return {
      dialog: false
    }
  },
  mounted() {
    eventBus.$on('show-about-dialog', (data) => {
      this.dialog = data;
    });
  },
  methods: {}
};
</script>

<style>
.logos {
  padding: 5px;
  border-radius: 5px;
  text-align: -webkit-center;
}
</style>
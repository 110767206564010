<template>
  <div>
   <SharedFilesTable />
  </div>
</template>

<script>
import SharedFilesTable from './SharedFilesTable.vue';

export default {
  name: "SharedFilesIndex",
  data() {
    return {
    };
  },
  components: {
    SharedFilesTable
},
  
};
</script>

<style scoped>
</style>
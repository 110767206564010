<template>
  <div class="container fill-height" style="height: calc(70vh)">
      <v-row class="text-center align-center justify-center">
          <h2>404 - Page Not Found</h2>
      </v-row>
  </div>
</template>

<script>

export default {
  name: "PageNotFound",
  data() {
      return {
      };
  },
  components: {
  },
  computed: {

  },
  methods: {
  },

};
</script>

<style scoped>

</style>
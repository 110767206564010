<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Share File: {{ fileName }}
        </v-card-title>

        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab href="#user-tab">
              Share with users
            </v-tab>
            <v-tab href="#group-tab">
              Share with groups
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="user-tab">
              <v-card flat>
                <v-card-text>
                  Please select the users to share the file with.
                  <v-autocomplete v-model="addUsers" :items="availableUsers" hide-details="auto" clearable hide-no-data
                    color="blue-grey lighten-2" item-text="username" item-value="username" multiple
                    prepend-icon="mdi-account-multiple-plus">
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                        @click:close="removeUser(data.item)">
                        {{ data.item.username }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.username"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="2" value="group-tab">
              <v-card flat>
                <v-card-text>
                  Please select the groups to share the file with.
                  <v-autocomplete v-model="addGroups" :items="availableGroups" hide-details="auto" clearable hide-no-data
                    color="blue-grey lighten-2" item-text="name" item-value="id" multiple
                    prepend-icon="mdi-account-multiple-plus">
                    <template v-slot:selection="data">
                      <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                        @click:close="removeGroup(data.item)">
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dialog = false">
            Close
          </v-btn>
          <v-btn text color="primary" :disabled="confirmBtnDisabled" @click="confirm()">
            Confirm
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios-services";
import { useUsersStore } from '@/stores/users';
import { useGroupsStore } from '@/stores/groups';
import { useMyFilesStore } from '@/stores/myfiles';
import { useUserStore } from '@/stores/user';
import { useAllFilesStore } from '@/stores/allfiles';

export default {
  name: "ShareFileDialog",
  data() {
    return {
      dialog: false,
      fileName: "",
      owner: null,
      addUsers: [],
      addedUsers: [],
      addedGroups: [],
      addGroups: [],
      groups: [],
      file_id: null,
      tab: "user-tab"
    };
  },
  components: {
  },
  computed: {
    confirmBtnDisabled() {
      if (this.tab === 'user-tab') { 
        return (this.addUsers.length === 0) 
      }
      if (this.tab === 'group-tab'){ 
        return (this.addGroups.length === 0) 
      }
      return false;
    },
    availableUsers() {
      let users = useUsersStore().getUsers;
      const user = useUserStore();
      if (this.owner) {
        users = users.filter(({ id }) => id !== this.owner);
      } else {
        users = users.filter(({ username }) => username !== user.username);
      }
      return users.filter(({ id }) => !this.addedUsers.includes(id));
    },
    availableGroups() {
      return useGroupsStore().groups.filter(({ id }) => !this.addedGroups.includes(id));
    }
  },
  mounted() {
    eventBus.$on('show-share-file-dialog', (data) => {  
      this.addGroups = [];
      this.addUsers = [];
      this.dialog = true;
      this.owner = null;
      if(data.owner) {
        this.owner = data.owner;
      }
      this.addedUsers = data.allowed_users;
      this.addedGroups = data.allowed_groups;
      this.fileName = data.name;
      this.tab = "users-tab";
      this.file_id = data.file_id;
    });
  },
  methods: {
    removeUser(item) {
      const index = this.addUsers.indexOf(item.username)
      if (index >= 0) this.addUsers.splice(index, 1)
    },
    removeGroup(item) {
      const index = this.addGroups.indexOf(item.id)
      if (index >= 0) this.addGroups.splice(index, 1)
    },
    async confirm() {
      console.log("confirm");
      if (this.tab === 'user-tab') {
        console.log("confirm user tab - ", this.addUsers);
        for (var user in this.addUsers) {
            var id = this.availableUsers.filter(item => { return item.username === this.addUsers[user] })[0].id;
            await this.share_with_user(id).then( () => {
              eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "File successfuly shared with user." });
            }).catch(( error ) => {
              eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "File could not be shared." });
              console.log("error: file could not be shared. details: ", error)
            }); 
          }
        useMyFilesStore().fetchMyFiles();
        useAllFilesStore().fetchAllFiles();
        this.dialog = false;
      } else if (this.tab === 'group-tab') {
        console.log("confirm group tab - ", this.addGroups);
        for (var group in this.addGroups) {
          await this.share_with_group(this.addGroups[group]).then(() => {
            eventBus.$emit("show-snackbar", { type: "success", title: "Success", msg: "File successfuly shared with group." });
          }).catch((error) => {
            eventBus.$emit("show-snackbar", { type: "error", title: "Error", msg: "File could not be shared." });
            console.log("error: file could not be shared. details: ", error)
          });
        }
        useMyFilesStore().fetchMyFiles();
        useAllFilesStore().fetchAllFiles();
        this.dialog = false;
      }
    },
    async share_with_user(id) {
      return axios_services.post("sharing/user", {file_id: this.file_id, user_id: id}).then(() => {});
    },
    async share_with_group(id) {
      return axios_services.post("sharing/group", {file_id: this.file_id, group_id: id}).then(() => {});
    }
  }
};
</script>

<style scoped>

</style>
